// **  Initial State
const initialState = {
  permission: {}
}

const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_permission':
      return { ...state, permission: action.permission }
    default:
      return state
  }
}

export default permissionReducer
