// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import setting from './system-setting'
import permission from './permission'
import product from './product'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  setting,
  permission,
  product,
})

export default rootReducer
