// ** Returns Initial Menu Collapsed State
const initialMenuDisplay = () => {
  const item = window.localStorage.getItem('productDetailDisplay')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : false
}

const initialPurchaseMenuDisplay = () => {
  const item = window.localStorage.getItem('purchaseDetailDisplay')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : false
}

const initialTransferMenuDisplay = () => {
  const item = window.localStorage.getItem('transferDetailDisplay')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : false
}

const initialSalesMenuDisplay = () => {
  const item = window.localStorage.getItem('salesDetailDisplay')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : false
}

const initialAdjustmentMenuDisplay = () => {
  const item = window.localStorage.getItem('adjustmentDetailDisplay')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : false
}

// **  Initial State
const initialState = {
  detailDisplayed: initialMenuDisplay(),
  purchaseDetailDisplayed: initialPurchaseMenuDisplay(),
  transferDetailDisplayed: initialTransferMenuDisplay(),
  salesMenuDisplay: initialSalesMenuDisplay(),
  adjustmentMenuDisplay: initialAdjustmentMenuDisplay(),
}

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_product_sidebar':
      window.localStorage.setItem('productDetailDisplay', action.data)
      return { ...state, detailDisplayed: action.data }
    case 'update_purchase_sidebar':
      window.localStorage.setItem('purchaseDetailDisplay', action.data)
      return { ...state, purchaseDetailDisplayed: action.data }
    case 'update_transfer_sidebar':
      window.localStorage.setItem('transferDetailDisplay', action.data)
      return { ...state, transferDetailDisplayed: action.data }
    case 'update_sales_sidebar':
      window.localStorage.setItem('salesDetailDisplay', action.data)
      return { ...state, salesMenuDisplay: action.data }
    case 'update_adjustment_sidebar':
      window.localStorage.setItem('adjustmentDetailDisplay', action.data)
      return { ...state, adjustmentMenuDisplay: action.data }
    default:
      return state
  }
}

export default productReducer
