// **  Initial State
const initialState = {
  system_setting: {}
}

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'update_system_setting':
      return { ...state, system_setting: action.system_setting }
    default:
      return state
  }
}

export default settingReducer
